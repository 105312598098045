html,body,#root{
    height: 100%;
}
.loading-class{
	position: absolute;
	width:0.4rem;
	height:0.4rem;
	top:50%;
	display: flex;
	align-items: center;
	justify-content: center;
	left: calc((100% - 0.4rem) / 2);
}

:root{
	--red: #e54d42;
	--orange: #f37b1d;
	--yellow: #fbbd08;
	--olive: #8dc63f;
	--green: #39b54a;
	--cyan: #1cbbb4;
	--blue: #0081ff;
	--purple: #6739b6;
	--mauve: #9c26b0;
	--pink: #e03997;
	--brown: #a5673f;
	--grey: #8799a3;
	--black: #333333;
	--darkGray: #666666;
	--gray: #aaaaaa;
	--ghostWhite: #f1f1f1;
	--white: #ffffff;
	/* 浅色 */
	--redLight: #fadbd9;
	--orangeLight: #fde6d2;
	--yellowLight: #fef2ce;
	--oliveLight: #e8f4d9;
	--greenLight: #d7f0db;
	--cyanLight: #d2f1f0;
	/* --blueLight: #cce6ff; */
	--blueLight: #e5f2ff;
	--purpleLight: #e1d7f0;
	--mauveLight: #ebd4ef;
	--pinkLight: #f9d7ea;
	--brownLight: #ede1d9;
	--greyLight: #e7ebed;
	/* 渐变色 */
	--gradualRed: linear-gradient(45deg, #f43f3b, #ec008c);
	--gradualOrange: linear-gradient(45deg, #ff9700, #ed1c24);
	--gradualGreen: linear-gradient(45deg, #39b54a, #8dc63f);
	--gradualPurple: linear-gradient(45deg, #9000ff, #5e00ff);
	--gradualPink: linear-gradient(45deg, #ec008c, #6739b6);
	--gradualBlue: linear-gradient(45deg, #0081ff, #1cbbb4);
	/* 阴影透明色 */
	--ShadowSize: 6rpx 6rpx 8rpx;
	--redShadow: rgba(204, 69, 59, 0.2);
	--orangeShadow: rgba(217, 109, 26, 0.2);
	--yellowShadow: rgba(224, 170, 7, 0.2);
	--oliveShadow: rgba(124, 173, 55, 0.2);
	--greenShadow: rgba(48, 156, 63, 0.2);
	--cyanShadow: rgba(28, 187, 180, 0.2);
	--blueShadow: rgba(0, 102, 204, 0.2);
	--purpleShadow: rgba(88, 48, 156, 0.2);
	--mauveShadow: rgba(133, 33, 150, 0.2);
	--pinkShadow: rgba(199, 50, 134, 0.2);
	--brownShadow: rgba(140, 88, 53, 0.2);
	--greyShadow: rgba(114, 130, 138, 0.2);
	--grayShadow: rgba(114, 130, 138, 0.2);
	--blackShadow: rgba(26, 26, 26, 0.2);
	/*home页面导航栏的高度*/
	--navHeight:40px;
	--homeGutter:10px;
	--bottomNav:45px;

	/*内容左右间距*/
	--leftRightMar:0.1rem;

	--swiper-pagination-color:#fff;
}

.ant-message-custom-content > i,.ant-message-custom-content > span{
	vertical-align: middle;
}
